import React from "react";
import * as DataFormat from "../../../Services/formartText";
import dayjs from "dayjs";
import {useChat} from "../../../Providers/chat";
import ImageIcon from "@material-ui/icons/Image";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {useTranslation} from "react-i18next";
import axios_base_chat from "../../../axios_base_chat";

import {Date, Item, Message, SttylesMessage, Text} from "./styles";
import MediaItem from "./components/MediaItem";

const ChatMessageItem = ({data, origin, type}) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const [donwloading, setDonwloading] = React.useState(false);

    const {chat} = useChat();
    const {t} = useTranslation();
    const classes = SttylesMessage();


    const [imagePath, setImagePath] = React.useState();
    const [msgType, setImgType] = React.useState();

    const download = (id, type) => {
        setDonwloading(true);
        setImgType(type);
        axios_base_chat.get(`chat/department/${chat.sectorID}/attendance/${parseInt(localStorage.getItem("chat"))}/message/${id}/download`)
            .then(res => {
                setImagePath(`${process.env.REACT_APP_LINK_API}/storage/${res.data.filename}`);
                setOpen(true);
                setDonwloading(false);
            })
            .catch(err => {

            });
    };
    const openLink = (link) => {
        window.open(link);
    };

    return (
        <Message style={{justifyContent: data?.key?.fromMe === true ? "flex-end" : "flex-start"}}>
            <Item className={data?.key?.fromMe === true ? classes.My : classes.Their}>
                {(
                    data?.message?.imageMessage ||
                    data?.message?.documentMessage ||
                    data?.message?.audioMessage ||
                    data?.message?.videoMessage
                ) && (
                    <MediaItem data={data}/>
                )}

                {data.message?.viewOnceMessage &&
                    <div style={{
                        height: "50px",
                        width: "250px",
                        cursor: "pointer",
                        paddingBottom: "5px",
                        paddingTop: "15px",
                        display: "flex",
                        color: data?.key?.fromMe === true ? "#fff" : "#999",
                    }}>
                        {donwloading === false ? <VisibilityOffIcon
                                style={{marginRight: "10px"}} /* onClick={() => download(data.key?.id, "viewOnceMessage")} */ /> :
                            <div style={{marginRight: "15px", marginLeft: "15px"}}><CircularProgress size={25}
                                                                                                     disableShrink/>
                            </div>}
                        <ImageIcon style={{marginLeft: "10px"}}/>
                        {t("words.image")} {t("words.unavailable")}
                    </div>
                }
                {data.message?.stickerMessage &&
                    <div style={{
                        height: "50px",
                        width: "250px",
                        cursor: "pointer",
                        paddingBottom: "5px",
                        paddingTop: "15px",
                        display: "flex",
                        color: data?.key?.fromMe === true ? "#fff" : "#999",
                    }}>
                        {/* {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} onClick={() => download(data.key?.id, "viewOnceMessage")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
                        <ImageIcon style={{marginLeft: "10px"}}/>
                        {t("words.unavailable")}
                    </div>
                }
                {data.message?.viewOnceMessageV2 &&
                    <div style={{
                        height: "50px",
                        width: "250px",
                        cursor: "pointer",
                        paddingBottom: "5px",
                        paddingTop: "15px",
                        display: "flex",
                        color: data?.key?.fromMe === true ? "#fff" : "#999",
                    }}>
                        {/* {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} onClick={() => download(data.key?.id, "viewOnceMessage")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
                        <ImageIcon style={{marginLeft: "10px"}}/>
                        {t("words.unavailable")}
                    </div>
                }
                {data.message?.ptvMessage &&
                    <div style={{
                        height: "50px",
                        width: "250px",
                        cursor: "pointer",
                        paddingBottom: "5px",
                        paddingTop: "15px",
                        display: "flex",
                        color: data?.key?.fromMe === true ? "#fff" : "#999",
                    }}>
                        {/* {donwloading === false ? <VisibilityOffIcon style={{ marginRight: '10px' }} onClick={() => download(data.key?.id, "viewOnceMessage")} /> :
                <div style={{ marginRight: '15px', marginLeft: '15px' }}> <CircularProgress size={25} disableShrink /> </div>} */}
                        <ImageIcon style={{marginLeft: "10px"}}/>
                        {t("words.unavailable")}
                    </div>
                }

                {data.message?.extendedTextMessage?.canonicalUrl &&
                    <div style={{height: "250px", width: "250px", cursor: "pointer", paddingBottom: "5px"}}
                         onClick={() => openLink(data.message?.extendedTextMessage.canonicalUrl)}>
                        <img style={{height: "90%", width: "100%", borderRadius: "6px"}}
                             src={`data:image/png;base64, ${data.message?.extendedTextMessage.jpegThumbnail}`}
                             alt={data.message?.extendedTextMessage.title}/>
                        <Text
                            dangerouslySetInnerHTML={{__html: DataFormat.formatText(data.message?.extendedTextMessage.title)}}
                            style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                marginTop: "15px",
                            }}>
                        </Text>
                    </div>
                }

                {data.message?.buttonsMessage &&
                    <div>
                        <Text
                            dangerouslySetInnerHTML={{__html: DataFormat.formatText(data.message.conversation ? data.message?.conversation : data.message.buttonsMessage?.contentText)}}></Text>
                        <div style={{background: "#FFF", color: "#202020", borderRadius: "6px", padding: "5px"}}>
                            {data.message.buttonsMessage.buttons && data.message.buttonsMessage.buttons.map((button) =>
                                <Text> {button.buttonText.displayText}</Text>,
                            )}
                        </div>
                    </div>
                }

                {data.message?.buttonsResponseMessage &&
                    <div>
                        <div style={{
                            padding: "5px",
                            borderRadius: "10px",
                            backgroundColor: data?.key?.fromMe === true && "#FFF",
                        }} className={data?.key?.fromMe === true ? classes.Their : classes.My}><p
                            dangerouslySetInnerHTML={{__html: DataFormat.formatText(data.message.conversation ? data.message?.conversation : data?.message?.buttonsResponseMessage?.contextInfo?.quotedMessage?.buttonsMessage?.contentText)}}></p>
                        </div>
                        <Text
                            dangerouslySetInnerHTML={{__html: DataFormat.formatText(data?.message?.buttonsResponseMessage?.selectedDisplayText)}}></Text>

                    </div>
                }

                {data.message?.extendedTextMessage?.contextInfo?.quotedMessage &&
                    <div style={{}}>
                        <div style={{
                            padding: "5px",
                            borderRadius: "10px",
                            backgroundColor: data?.key?.fromMe === true && "#FFF",
                        }} className={data?.key?.fromMe === true ? classes.Their : classes.My}><p
                            dangerouslySetInnerHTML={{__html: DataFormat.formatText(data.message?.extendedTextMessage?.contextInfo?.quotedMessage?.conversation)}}></p>
                        </div>
                    </div>
                }
                {data.message &&
                    <Text
                        dangerouslySetInnerHTML={{__html: DataFormat.formatText(data.message.conversation ? data.message?.conversation : data.message.extendedTextMessage?.text)}}></Text>
                }
                <Date style={{color: data?.key?.fromMe === true ? "#FFFF" : "#202020"}}>
                    {dayjs(data.sent_at).format("HH:mm")}
                </Date>
            </Item>
        </Message>
    );
};

export default ChatMessageItem;